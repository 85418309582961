import React, { useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';

const regSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Must be a valid email address')
    .required('Email is required'),
  phone: yup.string().required('Phone Number is required'),
  message: yup.string().required('Message is required'),
});

const ContactForm = () => {
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    submitting: false,
  });

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setState({ ...state, submitting: true });

    try {
      const isValid = await regSchema.isValid({
        name: state.name,
        email: state.email,
        phone: state.phone,
        message: state.message,
      });

      if (!isValid) {
        toast.error('All fields are mandatory.', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });

        setState({
          ...state,
          submitting: false,
        });

        return;
      }

      await fetch(`${process.env.GATSBY_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: state.name,
          email: state.email,
          phone: state.phone,
          message: state.message,
        }),
      });

      // reset the form
      setState({
        name: '',
        email: '',
        phone: '',
        message: '',
        submitting: false,
      });

      toast.success('Message sent successfully!', {
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    } catch (error) {
      toast.error(
        'Message Could not be sent due to a server problem. Please try again later.',
        {
          position: 'bottom-center',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );

      setState({
        ...state,
        submitting: false,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='relative w-full mb-3 mt-8'>
          <label
            className='block text-gray-700 text-xs font-bold mb-2'
            htmlFor='full-name'
          >
            Full Name
          </label>
          <input
            disabled={state.submitting}
            name='name'
            type='text'
            value={state.name}
            onChange={handleChange}
            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
            placeholder='Full Name'
            required
          />
        </div>

        <div className='relative w-full mb-3'>
          <label
            className='block text-gray-700 text-xs font-bold mb-2'
            htmlFor='email'
          >
            Email
          </label>
          <input
            disabled={state.submitting}
            name='email'
            type='email'
            value={state.email}
            onChange={handleChange}
            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
            placeholder='Email'
            required
          />
        </div>

        <div className='relative w-full mb-3 mt-3'>
          <label
            className='block text-gray-700 text-xs font-bold mb-2'
            htmlFor='phone'
          >
            Phone Number
          </label>
          <CurrencyFormat
            disabled={state.submitting}
            id='phone'
            name='phone'
            format='+1 (###) ###-####'
            mask='_'
            value={state.phone}
            onChange={handleChange}
            placeholder='Phone Number'
            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
            required
          />
        </div>

        <div className='relative w-full mb-3'>
          <label
            className='block text-gray-700 text-xs font-bold mb-2'
            htmlFor='message'
          >
            Message
          </label>
          <textarea
            disabled={state.submitting}
            rows='4'
            cols='80'
            name='message'
            value={state.message}
            onChange={handleChange}
            className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
            placeholder='Type a message...'
            required
          />
        </div>
        <div className='text-center mt-6'>
          <button
            className='bg-gray-900 text-white active:bg-gray-700 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-5 ease-linear transition-all duration-150'
            type='submit'
            disabled={state.submitting}
          >
            {state.submitting ? 'Sending...' : 'Send Message'}
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
