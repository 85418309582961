import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Layout from '../components/Layouts/Layout';
import SEO from '../components/seo';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  const [state, handleSubmit] = useForm('xoqojrja');
  return (
    <Layout>
      <SEO title='Contact' />
      <section className='pb-20 relative bg-gray-700'>
        <div className='container mx-auto px-4 lg:pt-24 lg:pb-64 py-16'>
          <div className='flex flex-wrap text-center justify-center pt-16'>
            <div className='w-full lg:w-6/12 px-4'>
              <h2 className='text-4xl font-semibold text-white'>Contact Us</h2>
            </div>
          </div>
          <div className='flex flex-wrap mt-12 justify-center'>
            <div className='w-full lg:w-3/12 px-4 text-center'>
              <div className='text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
                <i className='fas fa-map-marker text-xl' />
              </div>
              <h5 className='text-xl mt-5 font-semibold text-white'>
                540 South Martingale Road Schaumburg, IL 60193
              </h5>
            </div>
          </div>
          <div className='flex flex-wrap mt-12 justify-center'>
            <div className='w-full lg:w-3/12 px-4 mb-4 text-center'>
              <div className='text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
                <i className='fas fa-envelope text-xl' />
              </div>
              <h6 className='text-xl mt-5 font-semibold text-white'>
                <a href='mailto:temple@ydschicago.org'>temple@ydschicago.org</a>
              </h6>
            </div>
            <div className='w-full lg:w-3/12 px-4 text-center'>
              <div className='text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
                <i className='fas fa-phone-alt text-xl' />
              </div>
              <h5 className='text-xl mt-5 font-semibold text-white'>
                <a href='tel:8473805168'>(847) 380 5168</a>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className='relative block py-24 lg:pt-0 bg-gray-700'>
        <div className='container mx-auto px-4 py-10'>
          <div className='flex flex-wrap justify-center lg:-mt-64 -mt-48'>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300'>
                <div className='flex-auto p-5 lg:p-10'>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
